import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/Home';
import ScheduleDemoPage from './components/ScheduleDemo';
import ThankYou from './components/ThankYou';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import PageNotFound from './components/PageNotFound'; // Import the new component

function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/demo" element={<ScheduleDemoPage />} />
          <Route path="/thanks" element={<ThankYou />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
           <Route path="*" element={<PageNotFound />} /> {/* Add this line */}
        </Routes>
        <Footer/>
	<script
          src="https://app.10xlaunch.ai/widget"
          data-app-id="bad89868-761d-43c6-b7b5-b007ddb38860"
          async
          defer
        >
        </script>

      </div>
    </Router>
  );
}

export default App;
