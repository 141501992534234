import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';
import { FaArrowRight, FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { FaBuilding, FaIndustry, FaDatabase } from 'react-icons/fa';
import { FaBars, FaTimes } from 'react-icons/fa';
import { FaShieldAlt, FaUserCheck, FaCogs } from 'react-icons/fa';
import CaseStudySection from './CaseStudySection'; // Add this import
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Import the i18n configuration
import FlagLanguageSelector from './LanguageSelector'
import Header from './Header'
import Footer from './Footer'
import '../App.css';
import { useForm, ValidationError } from '@formspree/react';


function HomePage() {

  //const testimonials = [
    //{ text: "Right away I knew Social-Engineer, LLC needed to partner with them to bring cutting edge services to our space.", company: "CH, CEO, Social-Engineer, LLC" },
    //{ text: "IdentifAI offers a vital solution for protecting communications.", company: "CISO, Foreign Embassy" },
    //{ text: "The need for a KYE/KYC solution like IdentifAI is imperative for businesses in 2024 and beyond.", company: "CRO, Data removal company & enterprises & UHNW" },
  //];

  const { t } = useTranslation();

  // New function for lazy loading images
  const lazyLoad = (target) => {
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const src = img.getAttribute('data-src');
          img.setAttribute('src', src);
          img.classList.add('fade');
          observer.disconnect();
        }
      });
    });
    io.observe(target);
  };

  // Use effect for lazy loading
  useEffect(() => {
    const images = document.querySelectorAll('[data-src]');
    images.forEach(lazyLoad);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [activeTestimonial, setActiveTestimonial] = useState('government');

  const testimonials = {
    government: {
      title: t('governmentTitle'),
      icon: <FaBuilding />,
      text: t('governmentTestimonial'),
      author: t('governmentAuthor')
    },
    dataRecovery: {
      title: t('corporateSecurityTitle'),
      icon: <FaDatabase />,
      text: t('corporateSecurityTestimonial'),
      author: t('corporateSecurityAuthor')
    },
    industry: {
      title: t('internetMonitoringTitle'),
      icon: <FaIndustry />,
      text: t('internetMonitoringTestimonial'),
      author: t('internetMonitoringAuthor')
    }
  };


  const [activeProduct, setActiveProduct] = useState('know-your-employee');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const productInfo = {
    'know-your-employee': {
      title: t('kyeTitle'),
      tagline: t('kyeTagline'),
      description: t('kyeDescription'),
      details: [
        t('kyeDetail1'),
        t('kyeDetail2'),
        t('kyeDetail3'),
        t('kyeDetail4')
      ],
      slides: [
        {
          image: 'product/kye1.png',
          description: t('kyeSlide1')
        },
        {
          image: 'product/kye2.png',
          description: t('kyeSlide2')
        },
        {
          image: 'product/kye3.png',
          description: t('kyeSlide3')
        },
        {
          image: 'product/kye4.png',
          description: t('kyeSlide4')
        }
      ]
    },
    'jam': {
      title: t('jamTitle'),
      tagline: t('jamTagline'),
      description: t('jamDescription'),
      details: [
        t('jamDetail1'),
        t('jamDetail2'),
        t('jamDetail3'),
        t('jamDetail4'),
        t('jamDetail5')
      ],
      slides: [
        {
          image: 'product/jam1.png',
          description: t('jamSlide1')
        },
        {
          image: 'product/jam2.png',
          description: t('jamSlide2')
        },
        {
          image: 'product/jam3.png',
          description: t('jamSlide3')
        }
      ]
    },
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      Math.min(prevIndex + 1, productInfo[activeProduct].slides.length - 1)
    );
  };

  const teamMembers = [
    {
      name: "Justin Marciano",
      position: "CEO & Co-founder",
      image: "justin.png",
      linkedin: "https://linkedin.com/in/justin-marciano32/",
      twitter: "https://x.com/JustinMar32"
    },
    {
      name: "Paul Vann",
      position: "CTO & Co-founder",
      image: "paul.jpg",
      linkedin: "https://www.linkedin.com/in/paul-vann-b996b2120/"
    },
    {
      name: "Martin Roesch",
      position: "Advisor",
      image: "martin.jpg",
      linkedin: "https://www.linkedin.com/in/maroesch/"
    },
    {
      name: "Allison Miller",
      position: "Advisor",
      image: "allison.jpg",
      linkedin: "https://www.linkedin.com/in/allisonmiller/"
    },
    {
      name: "Jennifer Romeo",
      position: "Advisor",
      image: "jennifer.jpg",
      linkedin: "https://www.linkedin.com/in/jenniferromeo/"
    }
  ];

  const integrationLogos = [
    "zoom.png",
    "teams.png",
    "webex.png",
    "slack.png",
    "meet.png",
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  return (
    <div className="App">
      <Helmet>
        <title>IdentifAI - {t('welcome')}</title>
        <meta name="description" content="IdentifAI protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI." />
        <meta property="og:title" content="IdentifAI - Securing Enterprise Digital Identity" />
        <meta property="og:description" content="Protect against deepfakes and AI impersonation with IdentifAI" />
        <meta property="og:image" content="https://identifai.io/og-image.jpg" />
        <link rel="canonical" href="https://identifai.io" />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
      </Helmet>


      <main>
        <section className="hero">
          <video autoPlay muted loop playsInline className="hero-background-video">
            <source src="/background-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1>{t('welcome')}</h1>
            <p>{t('subtitle')}</p>
          </div>
        </section>

        <section id="product" className="comprehensive-protection">
        <div className="protection-content">
          <h2>{t('comprehensiveProtection')}</h2>
          <div className="protection-grid">
            <div className="protection-item">
              <h3>{t('proactiveDefense')}</h3>
              <p>{t('proactiveDefenseDescription')}</p>
            </div>
            <div className="protection-item">
              <h3>{t('liveVerification')}</h3>
              <p>{t('liveVerificationDescription')}</p>
            </div>
            <div className="protection-item">
              <h3>{t('adaptiveSecurity')}</h3>
              <p>{t('adaptiveSecurityDescription')}</p>
            </div>
            <div className="protection-item">
              <h3>{t('brandIntegrity')}</h3>
              <p>{t('brandIntegrityDescription')}</p>
            </div>
          </div>
        </div>
          <div className="protection-animation">
            <div className="animation-circle"></div>
            <div className="animation-logo">
              <img data-src="identifai-logo-anim.png" alt="IdentifAI Animated Logo" />
            </div>
            <div className="animation-item item1"><FaShieldAlt /></div>
            <div className="animation-item item2"><FaUserCheck /></div>
            <div className="animation-item item3"><FaCogs /></div>
            <div className="animation-item item4"><FaBuilding /></div>
          </div>
        </section>


        <section className="unified-platform">
           <h2>{t('unifiedPlatform')}</h2>
           <div className="product-selector">
             {Object.keys(productInfo).map((key) => (
               <button
                 key={key}
                 className={`product-button ${activeProduct === key ? 'active' : ''}`}
                 onClick={() => {
                   setActiveProduct(key);
                   setCurrentImageIndex(0);
                 }}
               >
                 {productInfo[key].title}
               </button>
             ))}
           </div>

        <div className="product-details-section">
          <div className="product-content">
            <div className="product-text">
              <h3>{productInfo[activeProduct].title}</h3>
              <p className="product-tagline">{productInfo[activeProduct].tagline}</p>
              <p className="product-description">{productInfo[activeProduct].description}</p>
              <div className="product-details">
  <h4>Key Features:</h4>
  <ul className="feature-list">
    {productInfo[activeProduct].details.map((detail, index) => (
      <li key={index} className="feature-item">
        <span className="feature-icon">✓</span>
        <span className="feature-text">{detail}</span>
      </li>
    ))}
  </ul>
</div>
            </div>
            <div className="product-image-slideshow">
              <img
                src={productInfo[activeProduct].slides[currentImageIndex].image}
                alt={`${productInfo[activeProduct].title} - Image ${currentImageIndex + 1}`}
              />
              <p className="slide-description">
                {productInfo[activeProduct].slides[currentImageIndex].description}
              </p>
              <div className="slideshow-controls">
                <button onClick={handlePrevImage} disabled={currentImageIndex === 0}>&lt;</button>
                <span>{currentImageIndex + 1} / {productInfo[activeProduct].slides.length}</span>
                <button onClick={handleNextImage} disabled={currentImageIndex === productInfo[activeProduct].slides.length - 1}>&gt;</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="key-stats">
          <div className="stats-container">
            <div className="stats-scroll">
              <div className="stat-card">
                <div className="stat-number">90<span>%</span></div>
                <p>{t('stat1')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">80<span>%</span></div>
                <p>{t('stat2')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">36<span>%</span></div>
                <p>{t('stat3')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">40<span>B</span></div>
                <p>{t('stat4')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">90<span>%</span></div>
                <p>{t('stat1')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">80<span>%</span></div>
                <p>{t('stat2')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">36<span>%</span></div>
                <p>{t('stat3')}</p>
              </div>
              <div className="stat-card">
                <div className="stat-number">40<span>B</span></div>
                <p>{t('stat4')}</p>
              </div>
              {/* Duplicate cards for seamless looping */}
              {/* ... (repeat the same structure for duplicated cards) */}
            </div>
          </div>
        </section>
      <section id="testimonials" className="testimonial-showcase">
                <div className="testimonial-content">
                  <h2>What Our Clients Say</h2>
                  <div className="testimonial-selector">
                    {Object.keys(testimonials).map((key) => (
                      <div
                        key={key}
                        className={`selector-item ${activeTestimonial === key ? 'active' : ''}`}
                        onClick={() => setActiveTestimonial(key)}
                      >
                        {testimonials[key].icon}
                        <span>{testimonials[key].title}</span>
                      </div>
                    ))}
                  </div>
                  <div className="testimonial-display">
                    <p className="testimonial-text">{testimonials[activeTestimonial].text}</p>
                    <p className="testimonial-author">- {testimonials[activeTestimonial].author}</p>
                  </div>
                </div>
              </section>

              <CaseStudySection />

              <section id="company" className="team-section">
                <h2>Meet Our Team</h2>
                <div className="team-grid">
                  {teamMembers.map((member, index) => (
                    <div key={index} className="team-card">
                      <img data-src={member.image} alt={member.name} className="team-member-image" />
                      <h3>{member.name}</h3>
                      <p>{member.position}</p>
                      <div className="social-links">
                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s LinkedIn`}>
                          <FaLinkedin className="social-icon" />
                        </a>
                        {member.twitter && (
                          <a href={member.twitter} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s Twitter`}>
                            <FaTwitter className="social-icon" />
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </section>

              <section id="contact" className="contact-section">
          <h2>{t('getInTouch')}</h2>
          <p className="contact-intro">{t('contactIntro')}</p>
          <div className="contact-container">
            <form className="contact-form" action="https://formspree.io/f/xyzgjaee" method="POST">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourName')}
                />
                <label>{t('yourName')}</label>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourEmail')}
                />
                <label>{t('yourEmail')}</label>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourMessage')}
                ></textarea>
                <label>{t('yourMessage')}</label>
              </div>
              <button type="submit" className="submit-button">
                {t('sendMessage')} <FaArrowRight />
              </button>
            </form>
            <div className="contact-info">
              <h3>{t('contactInformation')}</h3>
              <p>{t('contactDescription')}</p>
              <ul>
                <li><FaEnvelope /> <a href="mailto:justin@identifai.io">justin@identifai.io</a></li>
                <li><FaPhone /> <a href="tel:+15402870222">+1(540)287-0222</a></li>
              </ul>
            </div>
          </div>
        </section>
            </main>

            {/* Structured Data */}
            <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "IdentifAI",
              "url": "https://identifai.io",
              "logo": "https://identifai.io/identifai-logo.png",
              "description": "IdentifAI protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+15402870222",
                "contactType": "customer support",
                "email": "justin@identifai.io"
              },
              "sameAs": [
                "https://www.linkedin.com/company/identifai-io",
                "https://x.com/identifAI_"
              ]
            })}
            </script>
            </div>
  );
}

export default HomePage;
