import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './BlogPost.css'; // We'll create this CSS file


function parseFrontmatter(content) {
  const frontmatterRegex = /^---\s*([\s\S]*?)\s*---/;
  const match = frontmatterRegex.exec(content);
  if (!match) return { attributes: {}, content };

  const frontmatter = match[1];
  const attributes = {};
  frontmatter.split('\n').forEach(line => {
    const [key, ...rest] = line.split(':');
    if (key && rest.length) {
      attributes[key.trim()] = rest.join(':').trim();
    }
  });

  const contentWithoutFrontmatter = content.replace(frontmatterRegex, '').trim();
  return { attributes, content: contentWithoutFrontmatter };
}

const ImageRenderer = ({ src, alt }) => (
  <figure>
    <img src={src} alt={alt} className="blog-image" loading="lazy" />
    {alt && <figcaption>{alt}</figcaption>}
  </figure>
);

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const url = `/blogPosts/${slug}.md`;
        console.log('Fetching from URL:', url);
        const response = await fetch(url);
        console.log('Response status:', response.status);
        console.log('Response headers:', response.headers);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const content = await response.text();
        console.log('Raw content:', content);
        if (content.trim().startsWith('<!DOCTYPE html>')) {
          throw new Error('Received HTML instead of markdown. The file might not exist.');
        }
        const { attributes, content: parsedContent } = parseFrontmatter(content);
        setPost({ ...attributes, content: parsedContent });
        console.log('Successfully loaded post:', { ...attributes, content: parsedContent.substring(0, 100) });
      } catch (e) {
        console.error('Error loading post:', e);
        setError(e.toString());
      }
    };

    fetchPost();
  }, [slug]);

  if (error) {
    return (
      <div className="container">
        <Link to="/blog" className="btn back-btn">&larr; Back to Blog List</Link>
        <h1>Error</h1>
        <p>Unable to load blog post. Error details:</p>
        <pre>{error}</pre>
      </div>
    );
  }

  if (!post) {
    return <div className="container">Loading...</div>;
  }

  return (
    <div className="blog-post-container">
      <div className="blog-post-content">
        <Link to="/blog" className="back-btn">&larr; Back to Blog List</Link>
        <article className="blog-post">
          <header className="blog-header">
            
          </header>
          <div className="blog-body">
            <ReactMarkdown
              components={{
                img: ImageRenderer
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost;
